import { fromJS, Map } from "immutable";

import { normalize, getOrganizationId } from "Libs/utils";
import { ORGANIZATION_ID_FIELD } from "Constants/constants";
import logger from "Libs/logger";

const LOAD_ORGANIZATION_START = "app/organizations/load_organization_start";
const LOAD_ORGANIZATION_SUCCESS = "app/organizations/load_organization_success";
const LOAD_ORGANIZATION_FAILURE = "app/organizations/load_organization_failure";

const CREATE_ORGANIZATION_START = "app/organizations/create_organization_start";
const CREATE_ORGANIZATION_SUCCESS =
  "app/organizations/create_organization_success";
const CREATE_ORGANIZATION_FAILURE =
  "app/organizations/create_organization_failure";

const UPDATE_ORGANIZATION_START = "app/organizations/update_organization_start";
const UPDATE_ORGANIZATION_SUCCESS =
  "app/organizations/update_organization_success";
const UPDATE_ORGANIZATION_FAILURE =
  "app/organizations/update_organization_failure";

const DELETE_ORGANIZATION_START = "app/organizations/delete_organization_start";
const DELETE_ORGANIZATION_SUCCESS =
  "app/organizations/delete_organization_success";
const DELETE_ORGANIZATION_FAILURE =
  "app/organizations/delete_organization_failure";

const LOAD_ORGANIZATIONS_START = "app/organizations/load_organizations_start";
const LOAD_ORGANIZATIONS_SUCCESS =
  "app/organizations/load_organizations_success";
const LOAD_ORGANIZATIONS_FAILURE =
  "app/organizations/load_organizations_failure";

const LOAD_ORGANIZATION_MEMBERS_START =
  "app/organizations/load_organization_members_start";
const LOAD_ORGANIZATION_MEMBERS_SUCCESS =
  "app/organizations/load_organization_members_success";
const LOAD_ORGANIZATION_MEMBERS_FAILURE =
  "app/organizations/load_organization_members_failure";

const ADD_ORGANIZATION_MEMBER_START =
  "app/organizations/add_organization_member_start";
const ADD_ORGANIZATION_MEMBER_SUCCESS =
  "app/organizations/add_organization_member_success";
const ADD_ORGANIZATION_MEMBER_FAILURE =
  "app/organizations/add_organization_member_failure";

const DELETE_ORGANIZATION_MEMBER_START =
  "app/organizations/delete_organization_member_start";
const DELETE_ORGANIZATION_MEMBER_SUCCESS =
  "app/organizations/delete_organization_member_success";
const DELETE_ORGANIZATION_MEMBER_FAILURE =
  "app/organizations/delete_organization_member_failure";

export const updateOrganization = (organizationDescriptionId, organization) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_ORGANIZATION_START });

    try {
      const organizationToUpdate = await getOrganization(
        getState,
        organizationDescriptionId
      );
      const result = await organizationToUpdate.update(organization);
      const newOrganization = await result.getEntity();

      dispatch({
        type: UPDATE_ORGANIZATION_SUCCESS,
        payload: newOrganization
      });
    } catch (err) {
      logger(err, {
        action: "updateOrganization",
        organizationDescriptionId,
        organization
      });
      dispatch({
        type: UPDATE_ORGANIZATION_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export const deleteOrganization = organizationDescriptionId => {
  return async (dispatch, getState) => {
    dispatch({ type: DELETE_ORGANIZATION_START });

    try {
      const organizationToDelete = await getOrganization(
        getState,
        organizationDescriptionId
      );
      await organizationToDelete.delete();

      dispatch({
        type: DELETE_ORGANIZATION_SUCCESS,
        payload: organizationToDelete
      });
    } catch (err) {
      logger(err, {
        action: "deleteOrganization",
        organizationDescriptionId
      });
      dispatch({
        type: DELETE_ORGANIZATION_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export const createOrganization = organization => {
  return async dispatch => {
    dispatch({ type: CREATE_ORGANIZATION_START });

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const result = await client.createOrganization(organization);
      const newOrganization = await result.getEntity();

      dispatch({
        type: CREATE_ORGANIZATION_SUCCESS,
        payload: newOrganization
      });
    } catch (err) {
      logger(err, {
        action: "createOrganization",
        organization
      });
      dispatch({
        type: CREATE_ORGANIZATION_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

const getOrganization = async (getState, descriptionId) => {
  let organization = getState().organization.getIn(["data", descriptionId]);

  if (!organization) {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;

    organization = await client.getOrganization(
      getOrganizationId(getState, descriptionId)
    );
  }

  return organization;
};

export const loadOrganizationMembers = descriptionId => {
  return async (dispatch, getState) => {
    dispatch({ type: LOAD_ORGANIZATION_MEMBERS_START });

    try {
      const organization = await getOrganization(getState, descriptionId);

      const members = await organization.getMembers();

      dispatch({
        type: LOAD_ORGANIZATION_MEMBERS_SUCCESS,
        payload: members,
        meta: descriptionId
      });
    } catch (err) {
      logger(err, {
        action: "createOrganization",
        descriptionId
      });
      dispatch({
        type: LOAD_ORGANIZATION_MEMBERS_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export const deleteOrganizationMember = (descriptionId, memberId) => {
  return async (dispatch, getState) => {
    dispatch({ type: DELETE_ORGANIZATION_MEMBER_START });
    try {
      const member = getState().organization.getIn([
        "data",
        descriptionId,
        "members",
        memberId
      ]);
      await member.delete();

      dispatch({
        type: DELETE_ORGANIZATION_MEMBER_SUCCESS,
        payload: memberId,
        meta: descriptionId
      });
    } catch (err) {
      logger(err, {
        action: "deleteOrganizationMember",
        descriptionId,
        memberId
      });
      dispatch({
        type: DELETE_ORGANIZATION_MEMBER_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export const addOrganizationMembers = (descriptionId, member) => {
  return async (dispatch, getState) => {
    dispatch({ type: ADD_ORGANIZATION_MEMBER_START });

    try {
      const organization = await getOrganization(getState, descriptionId);

      const result = await organization.addMember(member);
      const newMember = await result.getEntity();

      dispatch({
        type: ADD_ORGANIZATION_MEMBER_SUCCESS,
        payload: newMember,
        meta: descriptionId
      });
    } catch (err) {
      logger(err, {
        action: "addOrganizationMembers",
        descriptionId,
        member
      });
      dispatch({
        type: ADD_ORGANIZATION_MEMBER_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export const loadOrganization = id => {
  return async dispatch => {
    dispatch({ type: LOAD_ORGANIZATION_START });

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const organization = await client.getOrganization(id);

      dispatch({
        type: LOAD_ORGANIZATION_SUCCESS,
        payload: organization
      });
    } catch (err) {
      logger(err, {
        action: "loadOrganization",
        id
      });
      dispatch({ type: LOAD_ORGANIZATION_FAILURE, error: true, payload: err });
    }
  };
};

export const loadOrganizations = () => {
  return async dispatch => {
    dispatch({ type: LOAD_ORGANIZATIONS_START });

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const organizations = await client.getOrganizations();

      dispatch({
        type: LOAD_ORGANIZATIONS_SUCCESS,
        payload: organizations
      });
    } catch (err) {
      logger(err, {
        action: "loadOrganizations"
      });
      dispatch({ type: LOAD_ORGANIZATIONS_FAILURE, error: true, payload: err });
    }
  };
};

export default function organizationReducer(state = new Map(), action) {
  switch (action.type) {
    case LOAD_ORGANIZATION_START:
    case LOAD_ORGANIZATIONS_START:
      return state.set("loading", true);
    case LOAD_ORGANIZATIONS_SUCCESS:
      return state
        .set("loading", false)
        .set("data", fromJS(normalize(action.payload, ORGANIZATION_ID_FIELD)))
        .set("errors", false);
    case UPDATE_ORGANIZATION_SUCCESS:
    case CREATE_ORGANIZATION_SUCCESS:
    case LOAD_ORGANIZATION_SUCCESS:
      return state
        .set("loading", false)
        .setIn(["data", action.payload.id], fromJS(action.payload))
        .set("errors", false);
    case DELETE_ORGANIZATION_MEMBER_SUCCESS:
      return this.state.deleteIn(["members", action.meta, action.payload]);
    case LOAD_ORGANIZATION_MEMBERS_SUCCESS:
      return state.setIn(
        ["members", action.meta],
        fromJS(normalize(action.payload, "user"))
      );
    case ADD_ORGANIZATION_MEMBER_SUCCESS:
      return state.setIn(
        ["members", action.meta, action.payload.user],
        action.payload
      );
    case LOAD_ORGANIZATIONS_FAILURE:
    case LOAD_ORGANIZATION_FAILURE:
      return state.set("loading", false).set("errors", action.payload);
    default:
      return state;
  }
}
